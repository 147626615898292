import { doc, cursor, scrl, getPosition, inViewport, registeredLoops, lenisScrollTo, stopLenis, startLenis, touchDevice} from './../../js/utils/events.js';

export default class ScrollFadeIn {
  init(root) {
    this.root = root //is an arrray

    this.root.classList.add('will-fade-in')

    registeredLoops.push({ //add this.loop to global requestAnimationFrame
      root: this.root,
      component: this,
      position: getPosition(this.root),
      loop: this.loop
    })
  }

  loop(time) {
    const inView = inViewport(this.position)
    if(inView.loopback === 1) {
      this.root.classList.add('fade-in')
    } else {
      this.root.classList.remove('fade-in')
    }
  }
}
